import React from 'react';
import {useFormik} from 'formik';
import {useParams,useHistory} from "react-router-dom";
import {Field, Form, Formik, FormikConfig, FormikValues,FormikHelpers, useField} from 'formik';
import {TextField, Checkbox} from 'formik-material-ui';
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";

const EditUser = () => {

    const {id} = useParams();
    const [users, setUsers] = React.useState(null);
    const history = useHistory();

    React.useEffect(() => {
        fetch('https://klettern.dav-duisburg.de/api/v1/user/edit/', {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("access_token")
                }
            }
        )
            .then(response => {
                if (response.status == 200) {
                    return response.json();
                } else if (response.status == 403) {
                        history.push(`/`);
                } else {
                    throw new Error('Something went wrong ...');
                }
            })
            .then(data => {
                console.log(data);
                setUsers(data);
                formik.handleChange()
            })
            .catch();
    }, [])


    const change =(values)=>{
        let newData = JSON.stringify({
            
            email: values.email,
            phoneNumber: values.phoneNumber,
            sektion: values.memberId.split("/")[0],
            ort: values.memberId.split("/")[1],
            member: values.memberId.split("/")[2],
            street: values.street,
            number: values.number,
            zipCode: values.zipCode,
            country: values.country
        });
        try{
            fetch("https://klettern.dav-duisburg.de/api/v1/user/edit", {
                method: 'put',
                body: newData,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + localStorage.getItem("access_token")
                }

            }).then(response => response)
                .then((response)=>{
                    console.log(response);
                    if(response.status==200){
                        alert("Daten wurden geändert");
                    }
                    else if (response.status == 403) {
                        history.push(`/`);
                    }

                })
        }
        catch (err){
            console.log(err)
        }
    }


    const formik = useFormik({
        initialValues: {
            firstName: users?.firstName,
            lastName: users?.lastName,
            email: users?.email,
            street: users?.street,
            number: users?.number,
            zipCode: users?.zipCode,
            country: users?.country,
            memberId:users?.sektion+'/'+users?.ort+'/'+users?.member


        },
        onSubmit: values => {
            alert(JSON.stringify(values, null, 2));
        },
        enableReinitialize: true,
    });
    return (

        <div className="body-2 d-md-flex align-items-center justify-content-center">
            <div className=" box-2 d-flex flex-column h-100">
                <div className="row align-items-center justify-content-between mb-4 mt-3">
                    {users!==null?
                        <Formik
                            initialValues={{
                                firstName: users?.firstName,
                                lastName: users?.lastName,
                                email: users?.email,
                                street: users?.street,
                                number: users?.number,
                                zipCode: users?.zipCode,
                                country: users?.country,
                                phoneNumber:users?.phoneNumber,
                                memberId:users?.sektion+'/'+users?.ort+'/'+users?.member
                                

                            }}
                            onSubmit={async(Values) => {
                                await change(Values).then((res) =>{
                                });

                            }}
                        >
                            <Form>
                                <Field id="firstName"
                                       name="firstName"
                                       type="text"
                                       component={TextField}
                                       label="Vorname"
                                       size="small"
                                       margin="dense"
                                       disabled></Field>
                                <Field id="lasName"
                                       name="lastName"
                                       type="text"
                                       component={TextField}
                                       label="Nachname"
                                       size="small"
                                       margin="dense"
                                       disabled></Field>
                                <Field id="email"
                                       name="email"
                                       type="email"
                                       component={TextField}
                                       label="Email"
                                       size="small"
                                       margin="dense"
                                       required></Field>
                                <Field id="street"
                                       name="street"
                                       type="text"
                                       component={TextField}
                                       label="Strasse"
                                       size="small"
                                       margin="dense"
                                       required></Field>
                                <Field id="number"
                                       name="number"
                                       type="text"
                                       component={TextField}
                                       label="Nummer"
                                       size="small"
                                       margin="dense"
                                       required></Field>
                                <Field id="zipCode"
                                       name="zipCode"
                                       type="text"
                                       component={TextField}
                                       label="PLZ"
                                       size="small"
                                       margin="dense"
                                       required></Field>
                                <Field id="country"
                                       name="country"
                                       type="text"
                                       component={TextField}
                                       label="Land"
                                       size="small"
                                       margin="dense"
                                       required></Field>
                                <Field id="phoneNumber"
                                       name="phoneNumber"
                                       type="tel"
                                       component={TextField}
                                       label="Telefon"
                                       size="small"
                                       margin="dense"
                                       required></Field>
                                <Field id="memberId"
                                       name="memberId"
                                       type="tel"
                                       component={TextField}
                                       label="Sektionsnummer"
                                       size="small"
                                       margin="dense"
                                       required></Field>
                                <div><Button type="submit">Ändern</Button></div>
                            </Form>
                        </Formik>
                        :"loading"}
                </div>
            </div>
        </div>
    );
};

export default EditUser;
import React from 'react';

const Footer = () => {
    return (
        <footer style={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: '#ccc', textAlign: 'center' }}>
            <a href={"https://www.dav-duisburg.de/footer/sockel/Impressum"}>Impressum</a>
            <a href={"https://www.dav-duisburg.de/datenschutz"} style={{ marginLeft: '10px' }}>Datenschutz</a>

        </footer>
    );
};

export default Footer;
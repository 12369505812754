import React from 'react';
import {Field, Form, Formik, FormikConfig, FormikValues,FormikHelpers, useField} from 'formik';
import {useParams,useHistory} from "react-router-dom";
import {TextField} from 'formik-material-ui';
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";

const PasswordRequest = () => {

    const history = useHistory();
    const {id} = useParams();
    const {t} = useTranslation();
    const change =(values)=>{
        let newPassword = JSON.stringify({
            id:1,
            newPassword:values.password,
        });
        //console.log(newPassword);
        try{
            console.log(id);
            fetch("https://klettern.dav-duisburg.de/api/v1/noneuser/lostPassword/"+values.username, {
                method: 'get',


            }).then(response => response)
                .then((response)=>{
                    console.log(response);
                    if(response.status==200){
                        alert("bitte dem Link in der erhaltenen Email folgen.");
                        history.push("/");
                    }else if(response.status==403){
                        alert("Der Link konnte nicht erstellt werden");
                    }
                    else{
                        alert("Der Benutzer wurde nicht gefunden");
                    }

                })
        }
        catch (err){
            console.log(err)
        }
    }
    return (
        <div>
            <div className="container">
                <div className="body d-md-flex align-items-center justify-content-between">
                    <div className="box-1 mt-md-0 mt-5"><img
                        src="Images/Backgrounds/Headerbild-14-Wolfgang-Ehn.jpg"
                        className="" alt=""/></div>
                    <div className="box-2 d-flex flex-column h-100">
                        <p className="mb-2 h-1">{t("changePassword.header")}</p><br/>
                        <a>{t("changePassword.requestText")}</a>

                        <Formik
                            initialValues={{
                                username: '',
                            }}
                            onSubmit={async(Values) => {
                                await change(Values).then((res) =>{
                                });

                            }}
                        >
                            <Form>
                                <Field name="username" component={TextField} type="text"
                                       label={t("changePassword.email")} size="small" margin="dense" required></Field>
                                <div><Button type="submit">{t("changePassword.requestButton")}</Button></div>

                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PasswordRequest;
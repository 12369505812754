import React from 'react';
import {Field, Form, Formik, FormikConfig, FormikValues,FormikHelpers, useField} from 'formik';
import {useParams,useHistory} from "react-router-dom";
import {TextField} from 'formik-material-ui';
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";

const LostPassword = () => {

    const history = useHistory();
    const {id} = useParams();
    const {t} = useTranslation();
    const change =(values)=>{
        let newPassword = JSON.stringify({
            id:1,
            newPassword:values.password,
        });
        //console.log(newPassword);
        try{
            console.log(id);
            fetch("https://klettern.dav-duisburg.de/api/v1/noneuser/password", {
                method: 'put',
                body: newPassword,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + id
                }

            }).then(response => response)
                .then((response)=>{
                    console.log(response);
                    if(response.status==200){
                        alert("Passwort wurde geändert.");
                        history.push("/");
                    }else if(response.status==403){
                        alert("Der Link ist abgelaufen, bitte einen neuen Link anfordern");
                    }
                    else{
                        alert("Passwort konnte nicht geändert werden.");
                    }

                })
        }
        catch (err){
            console.log(err)
        }
    }
    return (
        <div>
            <div className="container">
                <div className="body d-md-flex align-items-center justify-content-between">
                    <div className="box-1 mt-md-0 mt-5"><img
                        src="Images/Backgrounds/Headerbild-14-Wolfgang-Ehn.jpg"
                        className="" alt=""/></div>
                    <div className="box-2 d-flex flex-column h-100">
                        <p className="mb-2 h-1">{t("changePassword.header")}</p>
                        <Formik
                            initialValues={{
                                password: '',
                                passwordConfirm: '',
                            }}
                            onSubmit={async(Values) => {
                                await change(Values).then((res) =>{
                                });

                            }}
                        >
                            <Form>
                                <Field name="password" component={TextField} type="password"
                                       label={t("changePassword.password")} size="small" margin="dense" required></Field>
                                <Field name="passwordConfirm" component={TextField} type="password"
                                       label={t("changePassword.confirmpassword")} size="small" margin="dense"
                                       required></Field>
                                <div><Button type="submit">{t("changePassword.button")}</Button></div>

                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LostPassword;
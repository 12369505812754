import React from 'react';
import { Link, useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import {Field, Form, Formik, FormikConfig, FormikValues,FormikHelpers, useField} from 'formik';
import {TextField} from 'formik-material-ui';
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";

const Users = () => {
    
    const [users, setUsers]=React.useState(null);
    const [user, setUser]=React.useState(null);
    const history = useHistory();

    React.useEffect(() => {
        fetch('https://klettern.dav-duisburg.de/api/v1/users/'+user, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("access_token")
                }
            }
        )
            .then(response => {
                if (response.status==200) {
                    return response.json();
                }
                else if (response.status==403) {
                    history.push("/");
                }
                else {
                    throw new Error('Something went wrong ...');
                }
            })
            .then(data => {setUsers(data)})
            .catch();
    }, [user])

    const fetchData=()=> {
        return users?.sort((a,b)=> a.lastName > b.lastName).map((user, index) => {
            const { id, firstName, lastName, username } = user //destructuring
                return (
                    <tr key={id}>
                        <td>{firstName}</td>
                        <td>{lastName}</td>
                        <td>{username}</td>
                        <td><Button onClick={() => { history.push("/admin/edituser/"+id);}}>Bearbeiten</Button></td>
                    </tr>
                )
            });
    }
    
    
    return (
        <div>
            <h1 id='user'>User</h1>
            <Formik
                initialValues={{
                    user: '',
                }}
                onSubmit={(Values) => {
                    setUser(Values.user)

                }}
            >
                <Form>
                    <Field name="user" component={TextField} type="text"
                           label="User" size="small" margin="dense" required></Field>
                    <div><Button type="submit">Suchen</Button></div>
                </Form>
            </Formik>
            <table id='user'>
                <tbody>
                <tr>
                    <th>Vorname</th>
                    <th>Nachname</th>
                    <th>Benutzername</th>
                    <th>Bearbeiten</th>
                </tr>
                {fetchData()}
                </tbody>
            </table>
        </div>
    );
};

export default Users;
import React, {Component} from 'react';


function incNum (e) {
    if (e.state.num < 10) {
        e.setState({num: Number(e.state.num) + 1}, () => {
        e.state.onChange(e);})
    }
}

let decNum = (e) => {
    if (e.state.num > 0) {
        e.setState({num: e.state.num - 1}, () => {
        e.state.onChange(e);})
    }
}


class IncDecCounter extends Component {

    handleChange = (event) => {
        this.setState({num: event.target.value}, () => {
            this.state.onChange(this);})
    }
    constructor(props){

        super(props);
        this.state={
            name:props.name,
            num:props.value,
            price:props.price,
            onChange:props.onChange,
            id:props.id,
        };


    }


    render() {
        return (
            <>
                <div className="row ">
                    <div class="input-group align-items-center ">
                        <div className="input-group-prepend col-7">
                            <span>{this.state.name}</span>
                        </div>
                        <div className={"input-group col"}>
                            <div class="input-group-prepend">
                                <button class="btn btn-outline-primary" type="button" onClick={()=> {
                                    decNum(this)
                                }}>-</button>
                            </div>
                            <div class="col-3">
                                <input type="text" class="form-control" value={this.state.num} onChange={this.handleChange}/>
                            </div>
                            <div class="input-group-append">
                                <button class="btn btn-outline-primary" type="button" onClick={()=> {incNum(this)}}>+</button>
                                <span className="pl-2">&nbsp;&nbsp;{this.state.price*this.state.num}€</span>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default IncDecCounter;
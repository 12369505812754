import React, { useState } from "react";
import {
  Container,
  Card,
  Navbar,
  Nav,
  NavDropdown,
  Offcanvas,
} from "react-bootstrap";
import * as FaIcons from "react-icons/fa";
import * as FAB from "react-floating-action-button";

const Profile = () => {
  //Hooks
  const [showSettings, setShowSettings] = useState(false);
  const closeSettings = () => setShowSettings(false);
  const openSettings = () => setShowSettings(true);

  //Methods
  const onSaveSettings = (data) => {
    //TODO
  };

  const getUserData = (data) => {
    //TODO
  };

  const updateUserData = (data) => {
    //TODO
  };

  return (
    <div>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="light"
        variant="light"
        fixed="top"
      >
        {/*TopNavBar*/}
        <Container>
          <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto" >
              <Nav.Link href="#features">Features</Nav.Link>
              <Nav.Link href="#pricing">Pricing</Nav.Link>
              <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  Something
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Nav>
              <Nav.Link href="#deets">More deets</Nav.Link>
              <Nav.Link eventKey={2} href="#memes" onClick={openSettings}>
                Open Settings
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/*Main-Container*/}
      <Container style={{ "margin-top": "100px" }}>
        <Card>
          <Card.Header>//TODO</Card.Header>
          <Card.Body>
            <Card.Title>//TODO</Card.Title>
          </Card.Body>
          <Card.Footer className="text-muted">//TODO</Card.Footer>
        </Card>
      </Container>

      {/*Settings Tab*/}
      <Offcanvas show={showSettings} onHide={closeSettings}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          Some text as placeholder. In real life you can have the elements you
          have chosen. Like, text, images, lists, etc.
        </Offcanvas.Body>
      </Offcanvas>

      {/*Action Buttons*/}
      <FAB.Container>
        <FAB.Button tooltip="Open Settings" onClick={openSettings}>
          <FaIcons.FaMagic />
        </FAB.Button>
        <FAB.Button
          href="#"
          tooltip="Add user link"
          className="fab-item btn btn-link btn-lg text-white"
        >
          <FaIcons.FaUser />
        </FAB.Button>
        <FAB.Button
          tooltip="The big plus button!"
          rotate={true}
          onClick={() => alert("FAB Rocks!")}
        >
          <FaIcons.FaPlus />
        </FAB.Button>
      </FAB.Container>
    </div>
  );
};

export default Profile;

import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import * as moment from 'moment';
import jwt_decode from "jwt-decode";
import {Field, Form, Formik, FormikConfig, FormikValues,FormikHelpers, useField} from 'formik';
import {TextField} from 'formik-material-ui';
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";
import Refresh from "../helper/refresh"


class MyTickets extends Component {

    constructor() {
        super();

        this.state = {
            tickets: null,
            refresh:false,
        }
    }

    componentWillMount() {
        if (localStorage.getItem("access_token")) {

            if (jwt_decode(localStorage.getItem("access_token")).exp > Math.floor(Date.now() / 1000)) {
                if(localStorage.getItem("Order")){
                    try {
                        fetch("https://klettern.dav-duisburg.de/api/v1/shop/ticket", {
                            method: 'post',
                            body: localStorage.getItem("Order"),
                            headers: {
                                'Authorization': 'Bearer ' + localStorage.getItem("access_token"),
                                "Content-Type": "application/json"
                            }

                        })
                            .then(response => response)
                            .then((response)=>{
                                if(response.status==201){
                                    localStorage.removeItem("Order");
                                }
                                else{
                                    alert(localStorage.getItem("Order"));
                                }

                            })
                            .then(response => response)
                            .then((response) => {


                            })
                            .catch((err)=>{

                            })}
                    catch (err){
                        alert(err);
                    }
                }
                this.loadData();
            }
            else{
                this.props.history.push("/")
            }
        }
        else{
            this.props.history.push("/")
        }
    }

    redeem = (code) => {
        try {
            fetch('https://klettern.dav-duisburg.de/api/v1/shop/ticket/redeem/' + code, {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem("access_token")
                    }
                }
            ).then((response)=>this.loadData()
            );
        }
        catch (e){
            alert(e)
        }

    }
    loadData=()=>{
        fetch('https://klettern.dav-duisburg.de/api/v1/shop/user/ticket', {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("access_token")
                }
            }
        )
            .then(response => {
                if (response.status == 200) {
                    return response.json();
                } else if (response.status == 403) {
                    this.props.history.push("/")
                } else {
                    throw new Error('Something went wrong ...');
                }
            })
            .then(data => {
                this.setState({tickets: data})
            })
            .catch();
    }

    fetchData = () => {
        return this.state.tickets?.sort((a, b) => a.purchased > b.purchased).map((ticket, index) => {
            const {id, redeemed, voucher, item} = ticket //destructuring
            return (
                <tr key={id}>
                    <td>{redeemed ? moment(redeemed).format('DD.MM.YYYY') : "Frei"}</td>
                    <td>{voucher}</td>
                    <td>{item.name}</td>
                    <td>{redeemed ? null : <Button onClick={() => {
                        this.redeem(voucher);
                    }}>Einlösen</Button>}</td>
                </tr>
            )
        });
    }

    render() {
        const {i18nTranslation} = this.props;
        return (
            <div className="body-2 d-md-flex align-items-center justify-content-center">
                <div className=" box-3 d-flex flex-column h-100">
                    <div className="row align-items-center justify-content-between mb-4 mt-3">
                        <div>
                            <h1 id='user'>Ticket</h1>
                            <Formik
                                initialValues={{
                                    code: '',
                                }}
                                onSubmit={async(Values) => {
                                    await this.redeem(Values.code).then((res) =>{
                                    });

                                }}
                            >
                                <Form>
                                    <Field name="code" component={TextField} type="text"
                                           label={i18nTranslation.t("tickets.code")} size="small" margin="dense" required></Field>
                                    <div><Button type="submit">{i18nTranslation.t("tickets.redeem")}</Button></div>
                                </Form>
                            </Formik>
                            <table id='user'>
                                <tbody>
                                <tr>
                                    <th>{i18nTranslation.t("tickets.redeemed")}</th>
                                    <th>{i18nTranslation.t("tickets.code")}</th>
                                    <th>{i18nTranslation.t("tickets.ticket")}</th>
                                    <th>{i18nTranslation.t("tickets.redeem")}</th>
                                </tr>
                                {this.fetchData()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(function (props) {
    const i18nTranslation = useTranslation();
    return <MyTickets {...props} i18nTranslation={i18nTranslation} />
});
import React, {useRef, useState, useEffect} from "react";
import {
  Col,
  Row,

  InputGroup,
  Offcanvas,
  ProgressBar,
    Spinner,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "yup-phone";
import { useTranslation } from "react-i18next";
import {  useHistory } from "react-router-dom";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import IntlTelInput from 'react-bootstrap-intl-tel-input';
import QrReader from "react-qr-reader";
import { Field, Form, Formik, FormikConfig, FormikValues,useField } from 'formik';
import { Step, StepLabel,Button, Stepper } from '@material-ui/core';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import { mixed, number, object, string, ref} from 'yup';
import InputMask from "react-input-mask";
import styled from "styled-components/macro";
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';




const RegisterAdmin = () => {
    const sleep = (values,{ setSubmitting }) => new Promise((acc) => onRegister(values));
  const history = useHistory();
  const { t } = useTranslation();
    const [CovAlert,setCov]=React.useState(false);

  //Hooks

  //Pwd
  const [showPwd, toggleShowPwd] = React.useState(true);
  const [datePlh, datePlaceholder] = React.useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const closeSettings = () => setShowSettings(false);
  const [preloadedValues,setPreloaded]=React.useState(null);
  const [page,setPage]=React.useState(1);

    const MemberNumberMask = [
        /\d/,
        /\d/,
        /\d/,
        "/",
        /\d/,
        /\d/,
        /\d/,
        "/",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/
    ];

  // Form
    const onRegister = (data,actions) => {

        let registration = JSON.stringify({firstName: data.firstName,
            lastName: data.lastName,
            username: data.username,
            entryDate: "",
            exitDate: "",
            gender: "notspecified",
            email: data.email,
            language: "de",
            info: "",
            phoneNumber: data.phoneNumber,
            sektion: data.memberId.substr(0,3),
            ort: data.memberId.substr(4,2),
            member: data.memberId.substr(7,6),
            birthdate: data.birthdate,
            street: data.street,
            number: data.number,
            zipCode: data.zipCode,
            country: data.country,
            password: data.password,
            hash:"1"}
        );
        //console.log(registration);
        try{
        fetch("https://klettern.dav-duisburg.de/api/v1/registration", {
            method: 'post',
            body: registration,
            headers: {
                "Content-Type": "application/json"
            }

        })
            .then(response => response)
            .then((response)=>{
                console.log(response);
                if(response.status==201){
                    localStorage.setItem("registered",true);
                    alert("Account wurde erstellt, du hast eine Email erhalten. Bitte die Emailadresse bestätigen, danach ist der Login möglich");
                    history.push(`/`);
                }

            })
            .then(response => response)
            .then((response) => {


            })
            .catch((err)=>{

            })}
        catch (err){
            alert(err);
        }
    };





  //Methods

    const handleError = err => {
        console.error(err)
    }
    const phoneRegExp = /^((\+[1-9]{1,4}[ \-]*)([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/;

    const qrReader1 = useRef(null);
    const [datapre,setData]= useState(null);

    const returnStringFromObject=(data) =>{
        if(data!==null){
            return data;
        }
        else {
            return '';
        }
    }

  return (

      <div className="container">
        <div className="body d-md-flex align-items-center justify-content-between">
          <div className="box-1 mt-md-0 mt-5"><img
              src="Images/Backgrounds/Headerbild-14-Wolfgang-Ehn.jpg"
              className="" alt=""/></div>
          <div className=" box-2 d-flex flex-column h-100">
                    <FormikStepper
                        initialValues={{
                            username:'',
                            firstName:  '',
                            lastName:  '',
                            birthdate: '',
                            memberId:'000/00/000000',
                            phoneNumber:'+49',
                        }}
                        onSubmit={async(values, actions) => {
                            await sleep(values, actions).then((res) =>{
                                actions.setSubmitting(false);
                            });
                            console.log('values', values);
                        }}
                    >
                            <FormikStep
                                label="Benutzer"
                                validationSchema = { object({
                                    username: string().required("username not set").test('Einmaliger Username', 'Username schon benutz', // <- key, message
                                        async(value) => {
                                            return new Promise((resolve, reject) => {
                                                axios.get(`https://klettern.dav-duisburg.de/api/v1/user/available/${value}`)
                                                    .then((res) => {
                                                        if(res.status!=200){
                                                            resolve(false);
                                                        }
                                                        else{
                                                            resolve(true);
                                                        }

                                                    })
                                                    .catch((error) => {
                                                        if (error.response.data.content === "User already taken") {
                                                            resolve(false);
                                                            reject(true);
                                                        }
                                                    })
                                            })
                                        }
                                    ),
                                    password: string().required().min(6),
                                    passwordConfirm: string().oneOf([ref('password'), null], 'Passwords must match'),
                                })}

                            >
                                <Tooltip title="Add" disableTouchListener>
                                    <Field name="username" component={TextField} label={t("register.form.username.header")} autocomplete="username" size="small" margin="dense" required></Field>
                                </Tooltip>
                                <Field name="firstName"  component={TextField} label={t("register.form.firstname.header")} autocomplete="given-name" size="small" margin="dense" required></Field>
                                <Field name="lastName"  component={TextField} label={t("register.form.lastname.header")} autocomplete="family-name" size="small" margin="dense" required></Field>
                                <Field name="password" component={TextField} type="password"label={t("register.form.password.header")} size="small" margin="dense" required></Field>
                                <Field name="passwordConfirm" component={TextField} type="password" label={t("register.form.passwordConfirm.header")} size="small" margin="dense" required></Field>
                            </FormikStep>
                            <FormikStep
                                label="Kontakt"
                                validationSchema = { object({
                                    memberId:string().matches(/^([0-9]{3})(\/)([0-9]{2})(\/)([0-9]{6})/,"Mitgliedsnummer ist nicht im Richtigen Format (123/12/123456)"),
                                    email:string().email().required(),
                                    emailConfirm:string().oneOf([ref('email'), null], 'Email stimmt nicht überein'),
                                    phoneNumber: string().matches(phoneRegExp,"Die Telefonnummer muss mit einem Ländercode beginnen(z.B. +49)").phone("",true,"Die Telefonnummer entspricht keinem gültigen Format").required(),
                                })}>
                                <Field name="memberId" label={t("register.form.memberid.header")} size="small" margin="dense" component={TextField}></Field>
                                <br/>
                                <Field name="birthdate"  component={TextField} label={t("register.form.dateofbirth.header")} type="date" size="small" margin="dense" required></Field>
                                <br/>
                                <Field name="email" component={TextField} label={t("register.form.email.header")} type="email" autocomplete="email" size="small" margin="dense" required></Field>
                                <Field name="emailConfirm" component={TextField} label={t("register.form.email_r.header")} type="email" autocomplete="email" size="small" margin="dense" required></Field>
                                <Field name="phoneNumber" component={TextField} label={t("register.form.phonenumber.header")} type="tel" autocomplete="off" size="small" margin="dense" required></Field>
                                </FormikStep>
                            <FormikStep
                                label="Adresse"
                                validationSchema = { object({
                                    street: string().required(),
                                    number: string().required(),
                                    zipCode: string().required(),
                                    city: string().required(),
                                    country: string().required(),
                                })}>
                                <Field name="street" component={TextField} type="text" label={t("register.form.street.header")} size="small" margin="dense" required></Field>
                                <Field name="number" component={TextField} type="text" label={t("register.form.housenumber.header")} size="small" margin="dense" required></Field>
                                <Field name="zipCode" component={TextField} label={t("register.form.zipcode.header")} size="small" margin="dense" required></Field>
                                <Field name="city" component={TextField} label={t("register.form.city.header")} size="small" margin="dense" required></Field>
                                <Field name="country" component={TextField} label={t("register.form.country.header")} size="small" margin="dense" required></Field>
                            </FormikStep>
                    </FormikStepper>

          </div>
        </div>
      </div>
  );
};



const FormikStep=({ children }: FormikConfig<FormikValues>) => {
    return <>{children}</>;
}

const FormikStepper = ({children,...props} :  FormikConfig<FormikValues>) =>{

    const childrenArray = React.Children.toArray(children);
    const [step, setStep] = useState(0);
    const currentChild = childrenArray[step];
    const { t } = useTranslation();


    const isLastStep = () => {
            return step === childrenArray.length - 1;
    }



return(
    <Formik
        {...props}
        enableReinitialize={true}
        validationSchema ={currentChild.props.validationSchema}
        onSubmit={async (values,actions) => {

            if (isLastStep()) {
                await props.onSubmit(values,actions);
            } else {
                setStep((previousCount) => previousCount + 1);
                actions.setTouched({[values.phoneNumber]:false});
            }
        }}

    >
        {({isSubmitting, setFieldValue,values})=>(
        <div className="">
            <Stepper margin="0"  alternativeLabel activeStep={step}>
                {childrenArray.map((child, index) => (
                    <Step key={child.props.label} color ="#56AB27">
                        <StepLabel>{child.props.label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <p className="mb-1 h-1">{t("register.form.header")}</p>
            <div className="d-flex flex-column">
        <Form>

            {currentChild}
            <div class="Form-button">
                {step >0 ? <Button onClick={() => {setStep((s) => s - 1)}}>Zurück</Button> : null}
                {<Button startIcon={isSubmitting ? <Spinner animation="border" variant="primary" />:null}
                                   disabled={isSubmitting} type="submit">
                    {isLastStep()? 'Absenden' : 'Weiter'}
                </Button>}
            </div>
        </Form>
</div>
</div>
        )}
    </Formik>
);
};

export default RegisterAdmin;

import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
    Container,
    Card,
    ButtonGroup,
    ButtonToolbar,
    Button
} from "react-bootstrap";

import { Alert } from 'react-alert'
import * as FaIcons from "react-icons/fa";
import * as FAB from "react-floating-action-button";
import { useTranslation } from "react-i18next";

const Doors = () => {

    const history = useHistory();
    const [loadingData, setLoadingData] = useState(true);
    const [doors, setDoors]= useState({
        hits: [],
        isLoading: false,
        error: null
    }
);
    const { t } = useTranslation();
    const checkout=()=> {
        fetch('https://klettern.dav-duisburg.de/api/v1/user/checkout', {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("access_token")
                }
            }
        ).then(response => {
            {
                if (response.status == 403) {
                    history.push(`/`);
                } else if (response.status == 200){
                    history.push(`/checkin`);
                }
                else{
                    alert("Server Probleme")
                }
            }
        })

        localStorage.clear();
    }
    const fetchData=()=> {
       setDoors({ isLoading: true });
            fetch('https://klettern.dav-duisburg.de/api/v1/doors/available', {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem("access_token")
                    }
                }
            )
                .then(response => {
                    if (response.status==200) {
                        setLoadingData(false);
                        return response.json();
                    }else if(response.status==204){
                        history.push(`/checkin`);
                    }
                    else if (response.status==403) {
                        history.push(`/`);
                    }
                    else {
                        throw new Error('Something went wrong ...');
                    }
                })
                .then(data => setDoors({ hits: data, isLoading: false }))
                .catch(error => setDoors({ error, isLoading: false }));



    }

    const openDoor=(id)=> {
        setDoors({ isLoading: true });
        let url = 'https://klettern.dav-duisburg.de/api/v1/doors/open?door='+id;
        fetch(url, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("access_token")
                }
            }
        )
            .then(response => {

                if (response.status==200) {
                    alert('Öffnen angefragt')
                    return response;

                }
                else if (response.status==403) {
                    history.push(`/`);
                }
                else {
                    throw new Error('Something went wrong ...');
                }
            })
            .then(data => {

                setDoors({hits:doors.hits, isLoading: false});
            })
            .catch(error => {
                setDoors({error, isLoading: false});

            });

    }
    
    if(localStorage.getItem("access_token")){
        if(loadingData) {
            fetchData();
            setLoadingData(false);
        }
    }
    else{
        history.push(`/`);
    }

    if (doors.error) {
        return (<div><p>{doors.error.message}</p></div>);
    }

    if (doors.isLoading==true) {
        return (<div><p>Loading ...</p></div>);
    }

    else {
        return (
            <div className="container">
                <div className="body d-md-flex align-items-center justify-content-between">
                    <div className="box-1 mt-md-0 mt-5"><img
                        src="Images/Backgrounds/Headerbild-14-Wolfgang-Ehn.jpg"
                        className="" alt=""/></div>
                    <div className=" box-2 d-flex flex-column h-100">
                        <div className="">
                            <p className="mb-1 h-1">{t("open.header")}</p>
                            <a>{t("open.description")}</a>
                            <div className="d-flex justify-content-center mt-2">
            <ButtonToolbar aria-label="Toolbar with button groups">
                <ButtonGroup className="me-2" aria-label="First group" vertical>
                    {doors.hits.sort((a,b)=> a.identifier > b.identifier).map(hit =>
                        <Button
                            className = "mt-1"
                            key={hit.objectID}
                            onClick={() => openDoor(hit.id)}
                            >
                            {hit.identifier}

                        </Button>
                    )}
                </ButtonGroup>
            </ButtonToolbar>
                                <p/>
                                <Button
                                    className = "mt-1"
                                    onClick={() => checkout()}
                                >
                                    Checkout

                                </Button>
                            </div>
                    </div>
                </div>
                </div>
            </div>
        );
    }
}

export default Doors;
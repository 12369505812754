import React, { useState } from "react";
import { Link, useHistory,Route, useParams  } from "react-router-dom";
import {
    Container,
    Card,
    ButtonGroup,
    ButtonToolbar,
    Button
} from "react-bootstrap";

import { Alert } from 'react-alert'
import * as FaIcons from "react-icons/fa";
import * as FAB from "react-floating-action-button";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik, FormikConfig, FormikValues,useField,useFormik } from 'formik';
import TextField from '@material-ui/core/TextField';
import * as yup from "yup";

const Checkin = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const fetchData=(values)=>{
        fetch('https://klettern.dav-duisburg.de/api/v1/user/checkin/'+values.code, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("access_token")
                }
            }
        ).then(response => {
            if (response.status==201||response.status==200) {
                history.push(`/OpenDoor`);
            }else if (response.status==403) {
                history.push(`/`);
            }else if (response.status==204 && (response.headers.get('Item')!==null)) {
                history.push(`/user/paywall?item=`+response.headers.get('Item'));
                alert("Es gibt noch kein Ticket, Bitte ein Ticket einlösen oder ein neues Kaufen");
            }
            else if (response.status==204) {
                alert("Tor Code nicht korrekt");
            }
            else {
                alert("Server Probleme")
                throw new Error('Something went wrong ...');
            }
        })
    }

    const validationSchema = yup.object({
        code: yup.string().required("Code ist Notwendig")
            .matches(/^[0-9]+$/, "Darf nur aus Zahlen bestehen")
            .min(6, 'Muss genau 6 stellen haben')
            .max(6, 'Muss genau 6 stellen haben')
    });

    const formik = useFormik({
             initialValues: {
               code: '',
                 },
        validationSchema: validationSchema,
         onSubmit: values => {
             fetchData(values);
             },
           });

    const [loadingData, setLoadingData] = useState(true);
        return (

            <div className="container">
                <div className="body d-md-flex align-items-center justify-content-between">
                    <div className="box-1 mt-md-0 mt-5"><img
                        src="Images/Backgrounds/Headerbild-14-Wolfgang-Ehn.jpg"
                        className="" alt=""/></div>
                    <div className=" box-2 d-flex flex-column h-100">
                        <div className="">
                            <p className="mb-1 h-1">{t("checkin.header")}</p>
                            <a>{t("checkin.description")}</a>
                            <div className="mt-5">
                                <form className="d-flex flex-column" onSubmit={formik.handleSubmit}>
                                    <TextField
                                        className="flex-row"
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        style = {{width: 200}}
                                        id="code"
                                        name="code"
                                        label="Code"
                                        value={formik.values.code}
                                        onChange={formik.handleChange}
                                        error={formik.touched.code && Boolean(formik.errors.code)}
                                        helperText={formik.touched.code && formik.errors.code}
                                    />
                                    <Button className="mt-2 flex-row" type="submit">
                                        {t("checkin.button")}
                                </Button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default Checkin;
import React, {Component} from 'react';
import {withRouter} from "react-router-dom";

class LogOut extends Component {
    componentWillMount() {
        localStorage.clear();
        localStorage.setItem("registered",true)
        this.props.history.push("/")
    }

    render() {
        return (
            <div>
                
            </div>
        );
    }
}

export default withRouter(LogOut);
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import Login from "./users/Login";
import Profile from "./users/Profile";
import Register from "./users/Register";
import RegisterAdmin from "./admins/Register_admin";
import QrCode from "./users/qrCode";
import Doors from "./users/OpenDoor";
import Checkin from "./users/Checkin";
import Paywall from "./users/Paywall";
import PaywallAdmin from "./admins/PaywallAdmin";
import EditUsers from "./admins/EditUser";
import EditUser from "./users/EditUser";
import ActiveUsers from "./admins/ActiveUsers";
import Users from "./admins/Users";
import MyTickets from "./users/MyTickets";
import ChangePassword from "./users/ChangePassword";
import LostPassword from "./users/LostPassword";
import PasswordRequest from "./users/PasswordRequest";
import LogOut from "./helper/LogOut";
import ReactDOM from "react-dom";
import {
    Container,
    Card,
    Navbar,
    Nav,
    NavDropdown,
    Offcanvas,
} from "react-bootstrap";

import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {translations} from "./translations";

import {BrowserRouter as Router, Switch, Route, useHistory,withRouter} from "react-router-dom";
import {React, useState, useEffect} from "react";
import jwt_decode from "jwt-decode";
import Footer from './helper/Footer'

let language = localStorage.getItem("langauge") || "de";

i18n.use(initReactI18next).init({
    resources: translations,
    lng: language,
    fallbackLng: "de",
    interpolation: {
        escapeValue: false,
    },
});

function usePageViews() {

}

function App() {

    const [nav, showNav] = useState(false);
    const [navCont, setNav] = useState(null);

    function Navigation() {

        const history = useHistory();
        useEffect(() => {
            console.log("refresh")
            if (localStorage.getItem("access_token")) {
                if (jwt_decode(localStorage.getItem("access_token")).exp > Math.floor(Date.now() / 1000)) {
                    showNav(true);
                } else {
                    showNav(false);
                }

            } else {
                showNav(false);
            }

        }, [history])
        
        if (nav) {
            return <Navbar
                collapseOnSelect
                expand="lg"
                bg="light"
                variant="light"
                fixed="top"
            >
                {/*TopNavBar*/}
                <Container>
                    <Navbar.Brand href="#home">DAV Duisburg</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ml-auto">
                        </Nav>
                        <Nav>
                            {jwt_decode(localStorage.getItem("access_token")).userRoles.find(role => ["ROLE_ADMIN", "ROLE_MANAGER"].includes(role)) ?
                                <Nav.Link href="/admin/users">Users</Nav.Link> : null}
                            {jwt_decode(localStorage.getItem("access_token")).userRoles.find(role => ["ROLE_ADMIN", "ROLE_MANAGER"].includes(role)) ?
                                <Nav.Link href="/admin/register">Neuer Benutzer</Nav.Link> : null}
                            {jwt_decode(localStorage.getItem("access_token")).userRoles.find(role => ["ROLE_ADMIN", "ROLE_USER", "ROLE_MANAGER", "ROLE_MEMBER"].includes(role)) ?
                                <Nav.Link href="/checkin">Checkin</Nav.Link> : null}
                            {jwt_decode(localStorage.getItem("access_token")).userRoles.find(role => ["ROLE_ADMIN", "ROLE_USER", "ROLE_MANAGER", "ROLE_MEMBER"].includes(role)) ?
                                <Nav.Link href="/user/myTickets">Meine Tickets</Nav.Link> : null}
                            {jwt_decode(localStorage.getItem("access_token")).userRoles.find(role => ["ROLE_ADMIN", "ROLE_USER", "ROLE_MANAGER", "ROLE_MEMBER"].includes(role)) ?
                                <Nav.Link href="/user/paywall">Tickets Kaufen</Nav.Link> : null}
                            {jwt_decode(localStorage.getItem("access_token")).userRoles.find(role => ["ROLE_ADMIN", "ROLE_MANAGER"].includes(role)) ?
                                <Nav.Link href="/admin/paywall">Ticket Erstellen</Nav.Link> : null}
                            {jwt_decode(localStorage.getItem("access_token")).userRoles.find(role => ["ROLE_ADMIN", "ROLE_MANAGER"].includes(role)) ?
                                <Nav.Link href="/admin/activeUsers">Eingecheckte Benutzer</Nav.Link> : null}
                                <Nav.Link href="/user/edit">Mein Profil</Nav.Link>
                            {jwt_decode(localStorage.getItem("access_token")).userRoles.find(role => ["ROLE_ADMIN", "ROLE_USER", "ROLE_MANAGER", "ROLE_MEMBER"].includes(role)) ?
                                <Nav.Link href="/user/changePassword">Passwort ändern</Nav.Link> : null}
                            {jwt_decode(localStorage.getItem("access_token")).userRoles.find(role => ["ROLE_ADMIN", "ROLE_USER", "ROLE_MANAGER", "ROLE_MEMBER"].includes(role)) ?
                                <Nav.Link href="/logout">Log out</Nav.Link> : null}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        } else {
            return null;
        }
    }

    

    document.title = "Klettergarten DAV Duisburg";
    return (
        <>
        <Navigation />
    <Switch>
        <Route exact path="/">
            <Login/>
        </Route>
        <Route exact path="/admin/edituser/:id">
            <EditUsers/>
        </Route>
        <Route exact path="/user/edit">
            <EditUser/>
        </Route>
        <Route path="/register">
            <RegisterAdmin/>
        </Route>
        <Route path="/users/:userid">
            <Profile/>
        </Route>
        <Route path="/OpenDoor">
            <Doors/>
        </Route>
        <Route path="/Checkin">
            <Checkin/>
        </Route>
        <Route path="/admin/users">
            <Users/>
        </Route>
        <Route path="/admin/activeUsers">
            <ActiveUsers/>
        </Route>
        <Route path="/user/myTickets">
            <MyTickets/>
        </Route>
        <Route path="/admin/register">
            <RegisterAdmin/>
        </Route>
        <Route path="/user/paywall">
            <Paywall/>
        </Route>
        <Route path="/admin/paywall">
            <PaywallAdmin/>
        </Route>
        <Route path="/user/ChangePassword">
            <ChangePassword/>
        </Route>
        <Route path="/PasswordRequest">
            <PasswordRequest/>
        </Route>
        <Route path="/lostPassword/:id">
            <LostPassword/>
        </Route>
        <Route path="/logout">
            <LogOut/>
        </Route>
    </Switch>
            <Footer/>
            </>


);

}

export default withRouter(App);

import React, {Component} from 'react';
import {PayPalScriptProvider, PayPalButtons} from "@paypal/react-paypal-js";
import IncDecCounter from "../layouts/IncDecCounter";
import Modal from '../layouts/MyModal';
import { useTranslation } from "react-i18next";
import jwt_decode from "jwt-decode";
import onRefresh from"../helper/refresh"


function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;

    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

const Items = [
    {
        id: 1,
        name: "Nichtmitglied Erwachsen",
        price: 11.00,
        value:0
    },
    {
        id: 2,
        name: "Nichtmitglied Jugend",
        price: 5.00,
        value:0
    },
    {
        id: 3,
        name: "Fremdsektion Erwachsen",
        price: 8.00,
        value:0
    },
    {
        id: 4,
        name: "Nichtmitglied Kind",
        price: 4.00,
        value:0
    },];



class Paywall extends Component {
    handleChange=(e)=>{
        this.setState({totalAmount: e.state.num});
        Items[e.state.id-1].value=e.state.num;

    }

    componentWillMount() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const param = params.get('item');
        if(param) {
            Items[param - 1].value = 1;

            let newTotal = 0;
            for (let i = 0; i < Items.length; i++) {
                newTotal += Items[i].value * Items[i].price;
                if (Items[i].value != 0) {

                }
            }
            if (newTotal != this.state.totalAmount) {
                this.setState({totalAmount: newTotal});
            }
        }
    }

    componentDidUpdate() {
        let newTotal=0;
        for(let i=0;i<Items.length;i++){
            newTotal+=Items[i].value*Items[i].price;
            if(Items[i].value!=0){

            }
        }
        if(newTotal!=this.state.totalAmount){
            this.setState({totalAmount: newTotal});
        }

    }
    checkHandler=(e)=>{
        this.setState({Pay:!e.target.checked});
    }
    setModalShow=(bool)=>{
        this.setState({modal:bool});
    }


    constructor() {
        super();

        this.state=({
            totalAmount:0,
            modal:false,
            Pay:true,
            modalLabel:'paywall.successTitle',
            modalMessage:'paywall.successMessage',
        })

    }
    
    render() {
        const {i18nTranslation} = this.props;
        return (
                <div className="body-2 d-md-flex align-items-center justify-content-center">
                    <div className=" box-2 d-flex flex-column h-100">
                        <div className="row align-items-center justify-content-between mb-4 mt-3">
                            <div className={"col"}><IncDecCounter name={Items[0].name}
                                                                  price={Items[0].price}
                                                                  value={Items[0].value}
                                                                  id={Items[0].id}
                                                                  onChange={this.handleChange}
                            /></div>
                        </div>
                        <div className="row align-items-center justify-content-between mb-3">
                            <div className={"col"}><IncDecCounter name={Items[1].name}
                                                                  price={Items[1].price}
                                                                  value={Items[1].value}
                                                                  id={Items[1].id}
                                                                  onChange={this.handleChange}
                            /></div>
                        </div>
                        <div className="row align-items-center justify-content-between mb-3">
                            <div className={"col"}><IncDecCounter name={Items[2].name}
                                                                  price={Items[2].price}
                                                                  value={Items[2].value}
                                                                  id={Items[2].id}
                                                                  onChange={this.handleChange}
                            /></div>
                        </div>
                        <div className="row align-items-center justify-content-between mb-3">
                            <div className={"col"}><IncDecCounter name={Items[3].name}
                                                                  price={Items[3].price}
                                                                  value={Items[3].value}
                                                                  id={Items[3].id}
                                                                  onChange={this.handleChange}
                            /></div>
                        </div>
                        <div className="row align-items-center justify-content-between mb-3">
                            <div className={"col justify-content-between mb-3"}>
                                <span>Gesamtpreis: </span>
                                <span>{this.state.totalAmount}€</span><br/>
                                <input name={"AGB"} type="checkbox" onChange={e=>this.checkHandler(e)}/> Hiermit bestätige ich die<a href="https://dav-duisburg.de/klettergarten.html?view=article&id=224:agb&catid=13:klettergarten"> AGB</a>
                            </div>
                        </div>
                        <PayPalScriptProvider
                            options={{
                                "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                                "currency": "EUR",
                                "disable-funding":"giropay,sofort",
                            }}
                        >
                            <PayPalButtons
                                size={"small"}
                                disabled={this.state.Pay}
                                createOrder={(data, actions) => {
                                    return actions.order.create({
                                        purchase_units: [{
                                            invoice_id: makeid(8),
                                            amount: {
                                                value: this.state.totalAmount,
                                                currency_code: 'EUR',
                                                breakdown: {
                                                    item_total: {value: this.state.totalAmount, currency_code: 'EUR'}
                                                }
                                            },
                                            items: Items.filter(item=>item.value!=0).map((item)=>({
                                                name:item.name,
                                                unit_amount:{value:item.price.toString(), currency_code: 'EUR'},
                                                quantity: item.value.toString(),

                                    }))
                                        }],
                                    });
                                }}
                                onApprove={async (data, actions) => {
                                    const details = await actions.order.capture();
                                    const name = details.purchase_units[0].invoice_id;
                                    const body = JSON.stringify(Items.filter(item=>item.value!=0).map((item)=>({
                                        id:item.id,
                                        quantity:item.value,
                                        accounted: name,

                                    })));
                                    try{
                                        fetch("https://klettern.dav-duisburg.de/api/v1/shop/ticket", {
                                            method: 'post',
                                            body: body,
                                            headers: {
                                                'Authorization': 'Bearer ' + localStorage.getItem("access_token"),
                                                "Content-Type": "application/json"
                                            }

                                        })
                                            .then(response => response)
                                            .then((response)=>{
                                                if(response.status==201){
                                                    this.setModalShow(true);
                                                }
                                                else{
                                                    this.setState({modalLabel:'paywall.noSuccessTitle',modalMessage:'paywall.noSuccessMessage'},() => {this.setModalShow(true)});
                                                    localStorage.setItem("Order",body);
                                                }

                                            })
                                            .then(response => response)
                                            .then((response) => {


                                            })
                                            .catch((err)=>{

                                            })}
                                    catch (err){
                                        console.log(err);
                                    }
                                }}
                            />
                        </PayPalScriptProvider>
                        <Modal
                            show={this.state.modal}
                            onHide={() => this.setModalShow(false)}
                            lable={i18nTranslation.t(this.state.modalLabel)}
                            text={i18nTranslation.t(this.state.modalMessage)}
                            closeText={i18nTranslation.t('paywall.closeButton')}
                            btn1Text={i18nTranslation.t('paywall.ticketButton')}
                            onBtn1={event =>  window.location.href='/user/myTickets'}
                        />
                    </div>
                </div>
        );
    }
}

export default (function (props) {
    const i18nTranslation = useTranslation();
    return <Paywall {...props} i18nTranslation={i18nTranslation} />
});
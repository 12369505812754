import React from 'react';
import {Modal,Button} from "react-bootstrap";

const MyModal = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    DAV Duisburg
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>{props.lable}</h4>
                <p>{props.text}
                </p>
            </Modal.Body>
            <Modal.Footer>
                {props.btn1Text?<Button onClick={props.onBtn1}>{props.btn1Text}</Button>:null}
                <Button onClick={props.onHide}>{props.closeText ? props.closeText : "Close"}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MyModal;
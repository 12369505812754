import React, {useEffect} from "react";
import {
    Col,
    Row,
    Container,
    Card,
    Form,
    Button,
    InputGroup,
} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {FaEye, FaEyeSlash} from "react-icons/fa";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useTranslation} from "react-i18next";
import {Link, useHistory} from "react-router-dom";
import jwt_decode from "jwt-decode";


const schema = yup
    .object({
        username: yup.string().required(),
        password: yup
            .string()
            .required("Please enter your password")
        // .matches(
        //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        // ),
    })
    .required();


const Login = () => {
        //Hooks
        const [showPwd, toggleShowPwd] = React.useState(false);
        const {
            register,
            handleSubmit,
            formState: {errors},
        } = useForm({resolver: yupResolver(schema)});

        const {t} = useTranslation();
        const history = useHistory();

        useEffect(() => {
            if (localStorage.getItem("refresh_token")) {

                if (jwt_decode(localStorage.getItem("refresh_token")).exp > Math.floor(Date.now() / 1000)) {
                    onRefresh();
                }

            }

        })


        //Methods
        const onLogin = (data) => {

            let form = new FormData();
            form.append("username", data.username);
            form.append("password", data.password);

            fetch("https://klettern.dav-duisburg.de/api/login", {
                method: 'post',
                body: form,
            })
                .then((response) => {
                    console.log(response)
                    if(response.status==403){
                        console.log(response)
                        alert(t("login.form.alert"));
                    }
                    return response;
                })
                .then((response) => response.json())
                .then((response) => {
                    console.log(response);


                            let access = response.access_token;
                            let refresh = response.refresh_token;

                            localStorage.setItem("access_token", access);
                            localStorage.setItem("refresh_token", refresh);


                            if (access && refresh) {
                                if ((history.action !== 'POP') && !localStorage.getItem("registered")) {
                                    history.goBack();
                                } else {
                                    history.push(`/OpenDoor`);
                                }
                            }

                    }
                )
                .catch((err) => {

                });
        };
        const onRefresh = () => {


            fetch("https://klettern.dav-duisburg.de/api/v1/token/refresh", {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("refresh_token")
                }
            })
                .then(response => response.json())
                .then((response) => {

                    let access = response.access_token;
                    let refresh = response.refresh_token;

                    localStorage.setItem("access_token", access);
                    localStorage.setItem("refresh_token", refresh);


                    if (access && refresh) {
                        if ((history.action !== 'POP') && !localStorage.getItem("registered")) {
                            history.goBack();
                        } else {
                            history.push(`/OpenDoor`);
                        }
                    }

                })
                .catch((err) => {
                    console.log(err)
                });
        };

        return (
            <div className="container">
                <div className="body d-md-flex align-items-center justify-content-between">
                    <div className="box-1 mt-md-0 mt-5"><img
                        src="Images/Backgrounds/Headerbild-14-Wolfgang-Ehn.jpg"
                        className="" alt=""/>
                    </div>
                    <div className=" box-2 d-flex flex-column h-100">
                        <div className="">
                            <p className="mb-2 h-1">{t("login.form.header")}</p>
                            <a>{t("login.form.title")}</a>
                            <div className="d-flex mt-5">
                                <Form onSubmit={handleSubmit(onLogin)}>

                                    {/*Username*/}
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Control
                                            placeholder={t("login.form.username.header")}
                                            {...register("username")}
                                            className="border border-0 border-bottom border-primary rounded-0"
                                        />
                                    </Form.Group>
                                    {/*Password*/}
                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <InputGroup>
                                            <Form.Control
                                                type={showPwd ? "text" : "password"}
                                                placeholder={t("login.form.password.header")}
                                                {...register("password")}
                                                className="border border-0 border-bottom border-primary rounded-0"

                                            />
                                            <Button
                                                className="border border-0 border-bottom border-primary rounded-0"
                                                variant="outline-secondary"
                                                id="button-addon2"
                                                onClick={() => toggleShowPwd(!showPwd)}
                                            >
                                                {showPwd === true && <FaEye/>}
                                                {showPwd === false && <FaEyeSlash/>}
                                            </Button>
                                        </InputGroup>
                                    </Form.Group>
                                    {/*Login button*/}
                                    <Button variant="primary" type="submit">
                                        {t("login.form.loginButton.label")}
                                    </Button>
                                    <br/>
                                    <br/>

                                    <Link to={{pathname: '/register'}}>{t("login.form.footer")}</Link><br/><br/>
                                    <Link to={{pathname: '/PasswordRequest'}}>{t("login.form.lostPassword")}</Link>
                                </Form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
;

export default Login;

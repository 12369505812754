import React from 'react';
import { Link, useHistory } from "react-router-dom";
import { Button} from '@material-ui/core';

const ActiveUsers = () => {
    
    const [users, setUsers]=React.useState(null);
    const [length, setLength]=React.useState(0);
    const [loading, setloading]=React.useState(true);
    const history = useHistory();

    React.useEffect(() => {
        fetch('https://klettern.dav-duisburg.de/api/v1/users/active', {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("access_token")
                }
            }
        )
            .then(response => {
                if (response.status==200) {
                    return response.json();
                }
                else if (response.status==403) {
                    history.push("/");
                }
                else {
                    throw new Error('Something went wrong ...');
                }
            })
            .then(data => {setUsers(data);setLength(data.length);setloading(false)})
            .catch();
    }, [])

    const fetchData=()=> {
        return users?.sort((a,b)=> a.lastName > b.lastName).map((user, index) => {
            const { id, firstName, lastName, username } = user //destructuring
                return (
                    <tr key={id}>
                        <td>{firstName}</td>
                        <td>{lastName}</td>
                        <td>{username}</td>
                    </tr>
                )
            });

    }
    
    
    return (
        <div>
            <h1 id='user'>User</h1>
            <table id='user'>
                <tbody>
                <tr>
                    <th>Vorname</th>
                    <th>Nachname</th>
                    <th>Benutzername</th>
                </tr>
                {fetchData()}
                </tbody>
            </table>
            {loading? "Loading":"Anzahl der Besucher:"+length}

        </div>
    );
};

export default ActiveUsers;
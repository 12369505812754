export const translations = 
{
  en: {
    translation: {
      register: {
        form: {
          header: "Register to DAV-Lapacom",
          title: "To open our doors register to this app!",
          qrCode:{
            upload:"upload",
            scan:"Scan Qr Code"
          },
          username: {
            header: "Username",
            placeholder: "Enter your username here...",
            description: "",
          },
          password: {
            header: "Password",
            placeholder: "Password",
            description: "",
          },
          passwordConfirm: {
            header: "Cinfirm Password",
            placeholder: "Confirm Password",
            description: "",
          },
          firstname: {
            header: "Firstname",
            placeholder: "",
            description: "",
          },
          lastname: {
            header: "Lastname",
            placeholder: "",
            description: "",
          },
          email: {
            header: "Email",
            placeholder: "",
            description: "",
          },
          phonenumber: {
            header: "Phonenumber",
            placeholder: "",
            description: "",
          },
          memberid: {
            header: "Member Id",
            placeholder: "",
            description: "",
          },
          dateofbirth: {
            header: "Date of Birth",
            placeholder: "",
            description: "",
          },
          street: {
            header: "Street",
            placeholder: "",
            description: "",
          },
          housenumber: {
            header: "Nr",
            placeholder: "",
            description: "",
          },
          zipcode: {
            header: "ZIP",
            placeholder: "",
            description: "",
          },
          city: {
            header: "City",
            placeholder: "",
            description: "",
          },
          country: {
            header: "Country",
            placeholder: "",
            description: "",
          },
          registerButton: {
            label: "Register",
          },
          footer: "Already registered?",
        },
      },
      login: {
        form: {
          header: "Login to DAV",
          title: "Für Mitglieder",
          username: {
            header: "",
            placeholder: "",
            description: "",
          },
          password: {
            header: "",
            placeholder: "",
            description: "",
          },
          rememberMe: {
            header: "",
            placeholder: "",
            description: "",
          },
          loginButton: {
            label: "",
          },
          footer: "",
        },
      },
    }
  },
  de: {
    translation: {
      register: {
        form: {
          header: "Registrieren",
          title: "Zur Registrierung benötigen wir deinen Impfnachweis als QR Code, bitte diesen als Screenshot hochladen oder scannen.",
          qrCode:{
            label:"Impfstatus",
            upload:"Hochladen",
            scan:"Scannen"
          },
          username: {
            header: "Username",
            placeholder: "Username",
            description: "",
          },
          password: {
            header: "Passwort",
            placeholder: "Passwort",
            description: "",
          },
          passwordConfirm: {
            header: "Passwort wiederholen",
            placeholder: "Passwort wiederholen",
            description: "",
          },
          firstname: {
            header: "Vorname",
            placeholder: "",
            description: "",
          },
          lastname: {
            header: "Nachname",
            placeholder: "",
            description: "",
          },
          gender: {
            header: "Gender",
            placeholder: "",
            description: "",
          },
          email: {
            header: "Email",
            placeholder: "",
            description: "",
          },
          email_r: {
            header: "Email wiederholen",
            placeholder: "",
            description: "",
          },
          phonenumber: {
            header: "Telefonnummer",
            placeholder: "",
            description: "",
          },
          memberid: {
            header: "Mitgliedsnummer",
            placeholder: "",
            description: "",
          },
          dateofbirth: {
            header: "Geburtsdatum",
            placeholder: "",
            description: "",
          },
          street: {
            header: "Strasse",
            placeholder: "",
            description: "",
          },
          housenumber: {
            header: "Nr",
            placeholder: "",
            description: "",
          },
          zipcode: {
            header: "PLZ",
            placeholder: "",
            description: "",
          },
          city: {
            header: "Stadt",
            placeholder: "",
            description: "",
          },
          country: {
            header: "Land",
            placeholder: "",
            description: "",
          },
          registerButton: {
            label: "Registrieren",
          },
          footer: "Bereits registriert?",
          next: "Weiter",
        },
      },
      login: {
        form: {
          header: "Willkommen beim DAV-Duisburg",
          title: "Login zur Kletteranlage",
          username: {
            header: "Benutzername",
            placeholder: "Gebe hier deinen Benutzernamen ein...",
            description: "Benutzernamen vergessen?",
          },
          password: {
            header: "Passwort",
            placeholder: "Gebe hier dein Passwort ein...",
            description: "Passwort vergessen?",
          },
          rememberMe: {
            header: "Angemeldet bleiben",
          },
          loginButton: {
            label: "Anmelden",
          },
          alert: "Passwort oder Benutzername nicht korrekt.",
          footer: "Noch nicht registriert? Hier anmelden",
          lostPassword: "Passwort vergessen?",
        },
      },
      open: {
        header:"Öffnen",
        description:"Zum Öffnen der Tore, den entsprechenden Button drücken. " +
            "Die Öffnung kann bis zu 15 Sekunden dauern. Sobald am Knauf ein grünes Licht erscheint, kannst du diesen drehen und das Tor öffnen.",
      },
      checkin: {
        header:"Einchecken",
        description:"Bitte den am Tor angebrachten Tagescode eingeben. " +
            "Der Code muss nur einmal am Tag eingegeben werden. Bitte nach dem Klettern auschecken.",
        button:"Check-in"
      },
      paywall: {
        header:"Tickets Kaufen",
        successTitle:"Zahlung erfolgreich",
        noSuccessTitle:"Etwas ist schiefgelaufen.",
        noSuccessMessage:"Deine Bestellung ist eingegangen aber bei der Ticketerstellung ist etwas schiefgelaufen. Bitte besuche jetzt die Seite Meine Tickets damit deine Tickets erstellt werden können. Sollten deine Tickets nicht auftauchen, kontaktiere bitte den Support.",
        successMessage:"Deine Tickets wurden verbucht. Wenn du jetzt klettern möchtest, löse bitte ein Ticket für dich und jeden der mit dir klettert ein. Dieses Ticket ist ab dem Zeitpunkt des Einlösens für den aktuellen Tag gültig. " +
            "Du kannst auch einen Code weitergeben oder einen anderen Code einlösen.",
        closeButton:"Schließen",
        ticketButton:"Meine Tickets",
      },
      changePassword:{
        header:"Passwort ändern",
        requestText:"Bitte gib deinen Benutzernamen an, um einen Link, für ein neues Passwort, per Email zu erhalten.",
        password:"Neues Passwort",
        confirmpassword:"Passwort wiederholen",
        button:"Speichern",
        email:"Benutzername",
        requestButton:"Link Anfordern",
      },
      tickets:{
        header:"Ticket",
        redeemed:"Eingelöst",
        code:"Code",
        ticket:"Ticket",
        redeem:"Einlösen",

      }
    }
  }
};